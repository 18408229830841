<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('general.withdraw') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <loading-indicator v-if="!withdrawConfig" />
                    <template v-else>
                        <div v-if="withdrawConfig.errcode !== 0" class="alert alert-info">
                            {{ withdrawConfig.errmsg }}
                        </div>
                        <template v-else>
                            <!--
                                Identity verification
                            -->
                            <div v-if="withdrawConfig.data.identityVerified !== true">
                                <div class="alert alert-info">
                                    {{ $t('user.label_id_verification_desp') }}
                                    <div class="mt-3">
                                        <router-link to="/user/verifyid" class="btn btn-primary">{{ $t('user.label_id_verification') }}</router-link>
                                    </div>
                                </div>
                            </div>

                            <!--
                                Indicates a withdraw password must be set before a withdraw operation if asked by system settings.
                            -->
                            <div v-else-if="withdrawConfig.data.requiresWithdrawPassword === true && withdrawConfig.data.isWithdrawPasswordSet !== true">
                                <div class="alert alert-info">
                                    {{ $t('withdraw.missing_withdraw_pwd') }}
                                    <div class="mt-3">
                                        <router-link to="/user/changewithdrawpwd" class="btn btn-primary">{{ $t('withdraw_password.title') }}</router-link>
                                    </div>
                                </div>
                            </div>

                            <!--
                                Actual withdraw forms
                            -->
                            <template v-else>
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" v-bind:class="{ active: blockChainWithdraw }" @click="blockChainWithdraw = true">
                                            {{ $t('withdraw.label_coin_withdraw') }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;" v-bind:class="{ active: !blockChainWithdraw }" @click="blockChainWithdraw = false">
                                            {{ $t('withdraw.label_fiat_withdraw') }}
                                        </a>
                                    </li>
                                </ul>
                                <div class="card mt-0">
                                    <div class="card-body">
                                        <!-- Blockchain withdraw -->
                                        <block-chain-withdraw-form v-if="blockChainWithdraw" :currency="withdrawToken" />
                                        <template v-else>
                                            <!-- Fiat withdraw for USDT only -->
                                            <div v-if="bankAccounts && bankAccounts.length === 0" class="alert alert-info mt-3">
                                                <p>{{ $t('withdraw.missing_bank_account') }}</p>
                                                <p>
                                                    <router-link to="/user/bankaccount">{{ $t('withdraw.label_bank_account') }}</router-link>
                                                </p>
                                            </div>
                                            <withdraw-form-component v-else />
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import WithdrawFormComponent from './Components/WithdrawForm.vue';
import BlockChainWithdrawForm from './Components/BlockChainWithdrawForm.vue';

export default {
    components: { WithdrawFormComponent, BlockChainWithdrawForm },

    /**
     * id: specify the name of the currenty for withdraw.
     */
    props: ['id'],

    data() {
        return {
            withdrawConfig: null,
            // Indicates whether to display blockchain withdarw or not.
            blockChainWithdraw: true,

            // The token for withdraw.
            withdrawToken: null,
            bankAccounts: null
        };
    },
    watch: {
        '$i18n.locale': function () {
            this.getWithdrawConfigAsync().catch((err) => {
                this.withdrawConfig = {
                    // Unexpected error
                    errcode: -1,
                    errmsg: self.$t('general.http_error')
                };
            });
        }
    },
    created() {
        this.withdrawToken = (this.id || 'USDT').toUpperCase();
        this.initAsync();
        const self = this;
        self.getWithdrawConfigAsync().catch((err) => {
            self.withdrawConfig = {
                // Unexpected error
                errcode: -1,
                errmsg: self.$t('general.http_error')
            };
        });
    },

    methods: {
        initAsync: async function () {
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0) {
                this.bankAccounts = Object.freeze(json.data);
            }
        },
        getWithdrawConfigAsync: async function () {
            const self = this;
            const config = await $.callGetApi(self, '/api/v1/finance/withdrawconfig');
            self.withdrawConfig = Object.freeze(config);
        }
    }
};
</script>
<style scoped>
.nav-tabs {
    border-bottom: none;
}
</style>